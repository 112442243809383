import React from "react";
import { Link } from "gatsby";
import Date from "./date";
import "../style/list-blog.less";

export default function BlogGrid({ data, remove }) {
  const edges = data.allMarkdownRemark.edges

  return (
    <div className="row" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {edges.map(b => !(remove === b.node.id) // don't render if 'remove' id matches
        && <React.Fragment key={b.node.id}>
          <h6 className="text-primary center-text">
            <Link to={b.node.fields.slug} title={b.node.frontmatter.title} >
              {b.node.frontmatter.title}
            </Link>
          </h6>
          <Date data={b.node.frontmatter.date} />
          <br />
        </React.Fragment>
      )}
    </div>
  )
}
