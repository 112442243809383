import React from "react";

export default function DateComponent({ data, showDay = true, showMonth = true }) {
    const theDate = new Date(data)

    const day = theDate.getDate()
    const month = theDate.getMonth()
    const year = theDate.getFullYear();

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ]
    return (
        <span>
            {showMonth && months[month]} {showDay && day} {year}
        </span>
    );
}
