import React from "react";

export default function SectionTitle({ title }) {

  return (
    <h4 className="color-secondary">
      {title}
    </h4>
  )
}
