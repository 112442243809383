import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BlogItems from "./blog-grid";

export default function BlogPostsLatest(props) {
  // This is limit 4 because we only want to show the 4 latest posts, but that
  // will render as only 3 if you're currently reading one of the latest posts
  const query = useStaticQuery(graphql`
    query latestBlogList {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/blog/"} }
        limit: 4
        sort: {fields: [frontmatter___date], order: DESC}
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              description
              date
              image {
                publicURL
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                  id
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);
  if (query.allMarkdownRemark.edges.length > 0) {
    return (
      <section id="latest-blogposts" className="container">
        <div className="section-title">
          <h2>Latest Blog Posts</h2>
        </div>
        <BlogItems data={query} remove={props.id} />
      </section>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
}
